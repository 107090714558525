<template>
  <div style="width: 100%;height: 100%;overflow-y: auto">
    <div v-for="(question,index) in questionList" :key="index" class="item" @click="doSelectQuestion(question)">
      <p class="question-title-record" v-if="question.record">
        {{ question.title }}
      </p>
      <p v-else :class="selected_question_id == question._id.$id?'question-title-selected':'question-title' ">
        {{ question.title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionListView",
  props: {
    selected_question_id: {
      type: String,
      default: ''
    },
    questionList: {
      type: Array,
      default: []
    }
  },
  methods: {
    doSelectQuestion(question) {
      this.$emit("selectQuestion", {question_id: question._id.$id});
    }
  }
}
</script>

<style scoped lang="less">
.item {
  cursor: pointer;
  width: 100%;
  padding: 23px 32px;
  box-sizing: border-box;
  font-size: 18px;
  font-family: Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20;
  font-weight: normal;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 1px;
}

.question-title {
  color: #666666;
}

.question-title-selected {
  color: #D60034;
}

.question-title-record {
  color: #00C167;
}
</style>
