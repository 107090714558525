<template>
  <div class="questions-container">

    <div class="questions-content">
      <div style="display: flex;flex-direction: row;align-items: center;justify-content: center">
        <p class="case-title" style="flex: 1">{{ caseInfo.title }}</p>
        <div style="display:flex;">
          <p class="question-list" @click="clickQuestionViewShowChanged">
            {{ showQuestionView ? 'Hide Question' : 'View Question' }}</p>
          <p class="question-list" style="margin-left: 20px" @click="showQuestionListView=true">Dialogue List</p>
        </div>
      </div>
      <div class="question-main-content" id="questionMainContent">
        <VideoView id="videoView" ref="videoView" style="position: absolute;width: 100%;;height: 100%;"
                   @videoEnded="handleVideoEnded" @videoPlay="handleVideoPlay"
                   @videoTimeUpdated="checkShowQuestionReviewOptionView"
                   @hideReviewQuestionView="resetReviewQuestionView"/>
        <div v-if="showCoverView == true" id="videoCover"
             style="width: 100%;height: 100%;position: absolute;background-color:  #F8F8F8"></div>
        <QuestionOptionView v-if="showQuestionView" :question="currentQuestionAndRecord"
                            @submitAnswer="submitQuestionAnswer"
                            ref="questionOptionView"/>
        <QuestionReviewOptionView ref="reviewOptionView" class="reviewOptionViewStyle" v-if="showReviewQuestionView"
                                  :question="reviewQuestionAndRecord" @closeView="resetReviewQuestionView"/>
      </div>
    </div>
    <el-drawer
        title="Dialogue List"
        :visible.sync="showQuestionListView"
        direction="rtl">
      <QuestionListView :question-list="case_questions" :selected_question_id="selected_question_id"
                        @selectQuestion="handleOptionViewSelected"/>
    </el-drawer>
  </div>
</template>

<script>
import {answerQuestion, getCaseInfo, getCaseQuestionAndRecords, getCaseRecord, getQuestionAndRecord} from '../api/ipsye'
import {getUserId} from "../utils/store";
import VideoView from "../components/VideoView"
import QuestionListView from "../components/QuestionListView";
import QuestionOptionView from "../components/QuestionOptionView";
import QuestionReviewOptionView from "../components/QuestionReviewOptionView";

export default {
  name: "CaseQuestionsAndAnswer",
  components: {VideoView, QuestionListView, QuestionReviewOptionView, QuestionOptionView},
  data() {
    return {
      videoUrl: "https://vid.cdn-website.com/2f9f8786/videos/whPPONPVT4GDA7KzgFuH_APA-Academic+Writer-CC-v.mp4",
      student_id: getUserId(),
      case_id: this.$route.query.case_id,
      case_record_id: this.$route.query.case_record_id,
      showQuestionListView: false,

      caseInfo: {},
      case_record: {},
      case_questions: [],

      currentQuestionAndRecord: {
        question: {}
      },
      selected_question_id: '',
      showQuestionView: false,
      showCoverView: false,
      showReviewQuestionView: false,
      reviewQuestionAndRecord: {},
      reviewQuestionTimer: undefined,
      caseFinished: false
    }
  },
  beforeDestroy() {
    if (this.reviewQuestionTimer) {
      clearTimeout(this.reviewQuestionTimer)
    }
  },
  mounted() {
    getCaseInfo(this.case_id).then((res) => {
      this.caseInfo = res.data.data
    })
    this.refreshCaseCaseQuestionAndRecords(false)
  },
  methods: {

    clickQuestionViewShowChanged() {
      this.showQuestionView = !this.showQuestionView
      if (this.showQuestionView == true) {
        this.resetReviewQuestionView()
      }
    },
    resetReviewQuestionView() {
      if (this.showReviewQuestionView == true) {
        let that = this
        this.$refs['reviewOptionView'].resetData()
        this.$nextTick(() => {
          that.showReviewQuestionView = false
        })
      }
    },
    handleVideoPlay() {
      let that = this
      this.$nextTick(() => {
        that.showQuestionView = false
      })
    },
    checkShowQuestionReviewOptionView() {

      if (this.reviewQuestionTimer) {
        clearTimeout(this.reviewQuestionTimer)
      }

      // if (this.caseFinished == true) {
      //   return
      // }

      let question_id = this.currentQuestionAndRecord.question._id.$id
      let ddlTime = 0
      if (this.currentQuestionAndRecord.question.visitor_listen_ddl_time) {
        ddlTime = this.currentQuestionAndRecord.question.visitor_listen_ddl_time
      }
      let currentTime = this.$refs['videoView'].getVideoCurrentTime()

      if (ddlTime > 0 && currentTime < ddlTime / 2) {
        let questionIndex = -1
        for (let i = 0; i < this.case_questions.length; i++) {
          let questionAndRecord = this.case_questions[i]
          if (questionAndRecord._id.$id == question_id) {
            questionIndex = i
            break
          }
        }
        if (questionIndex > 0) {
          questionIndex--
          let questionAndRecord = this.case_questions[questionIndex]
          if (questionAndRecord.record) {
            questionAndRecord.ddlTime = ddlTime
            this.reviewQuestionAndRecord = questionAndRecord
            if (this.showReviewQuestionView == false) {
              this.showReviewQuestionView = true
            } else {
              this.$refs['reviewOptionView'].formatOptions(questionAndRecord)
            }
          }
        }
      }
    },

    handleVideoEnded() {
      let that = this
      this.resetReviewQuestionView()
      this.$nextTick(() => {
        that.showQuestionView = true
      })
    },

    refreshCaseCaseQuestionAndRecords(refresh) {
      getCaseQuestionAndRecords(this.case_id, this.student_id).then((res) => {
        let result = res.data.data
        this.case_questions = result.questions
        this.caseFinished = result.finished
        this.refreshCaseRecord(refresh, result.finished)
      })
    },
    refreshCaseRecord(forceRefresh, finished) {
      getCaseRecord(this.case_id, this.student_id).then((res) => {
        let result = res.data.data
        this.case_record = result
        let question_id_cursor = this.case_record.question_id_cursor
        let params = {
          question_id: question_id_cursor
        }
        this.handleQuestionSelected(forceRefresh, params, finished)
      })
    },
    handleOptionViewSelected(params) {
      let enableSelect = false
      let question_id = params.question_id
      for (let i = 0; i < this.case_questions.length; i++) {
        let question = this.case_questions[i]
        if (question._id.$id == question_id) {
          if (question.record && question.record != null) {
            enableSelect = true
          }
        }
      }
      if (enableSelect) {
        this.handleQuestionSelected(false, params, false)
        return
      }
      if (this.currentQuestionAndRecord.record && this.currentQuestionAndRecord.record != null) {
        this.handleQuestionSelected(false, params, false)
      } else {
        this.$message.error('Please finish the current question first.')
      }
    },
    handleQuestionSelected(forceRefresh, params, case_finished) {
      let select_question_id = params.question_id
      if (!forceRefresh && this.currentQuestionAndRecord && this.currentQuestionAndRecord.question && this.currentQuestionAndRecord.question._id && this.currentQuestionAndRecord.question._id.$id == select_question_id) {
        //选择了相同的question_ID ，不处理
        return
      }
      let question_record_id = ''
      if (this.case_record.answered_ids[select_question_id] != null) {
        question_record_id = this.case_record.answered_ids[select_question_id]
      }
      this.queryCurrentQuestionInfo(select_question_id, question_record_id, case_finished)
    },
    queryCurrentQuestionInfo(question_id, question_record_id, case_finished) {
      //当前QUESTION 是否有倾听部分，如果就显示上个QUESTION的答案
      getQuestionAndRecord(question_id, question_record_id,this.student_id).then((res) => {
        this.showQuestionView = false
        this.showCoverView = false
        this.showReviewQuestionView = false
        this.currentQuestionAndRecord = res.data.data
        this.selected_question_id = this.currentQuestionAndRecord.question._id.$id

        if (case_finished) {
          this.$message.success('Congratulations! You have finished this exercise.')
        }
        if (this.currentQuestionAndRecord.question.question_video_address && this.currentQuestionAndRecord.question.question_video_address != '') {
          let url = this.currentQuestionAndRecord.question.question_video_address

          let ddlTime = 0
          if (this.currentQuestionAndRecord.question.visitor_listen_ddl_time) {
            ddlTime = this.currentQuestionAndRecord.question.visitor_listen_ddl_time
          }
          this.$refs['videoView'].addVideoSourceAndToPlay(url, ddlTime, case_finished)
        } else {
          this.showCoverView = true
          this.showQuestionView = true
          this.$refs['videoView'].pausePlayer()
        }
      })
    },
    submitQuestionAnswer() {
      let result = this.$refs['questionOptionView'].getSelectAnswers()
      if (null == result) {
        return
      }
      let answers = result.answers
      let correct = result.right

      let params = {
        student_id: this.student_id,
        case_record_id: this.case_record_id,
        question_id: this.currentQuestionAndRecord.question._id.$id,
        answers: answers,
        correct: correct,
        case_id: this.case_id
      }
      answerQuestion(params).then((res) => {
        let result = res.data
        if (result.code == -1) {
          this.$message.error(result.msg)
          return
        }
        this.$message.success('Submission success!')
        this.refreshCaseCaseQuestionAndRecords(true)
      })
    },
  }
}
</script>

<style scoped lang="less">


.reviewOptionViewStyle {
  height: 84px;
  position: absolute;
  bottom: 1.85em;
  width: 85%;
  max-width: 960px;
  margin: 0 auto;
}

.question-main-content {
  padding: 0px;
  margin: 0 auto;
  margin-top: 20px;
  width: 960px;
  height: 554px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

::-webkit-scrollbar {
  display: none;
}

.question-list {
  cursor: pointer;
  text-align: center;
  padding: 0px 20px;
  height: 44px;
  line-height: 44px;
  background: #DB1F4C;
  border-radius: 6px 6px 6px 6px;
  font-size: 20px;
  font-family: Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #FFFFFF;
}

.questions-container {
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  height: 90vh;
  background-color: #F8F8F8
}

.questions-content {
  padding: 20px 140px;
  box-sizing: border-box;
}

.case-title {
  font-weight: bold;
  font-size: 24px;
  font-family: Alibaba PuHuiTi 2.0-85 Bold, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #333333;
}

</style>
