<template>
  <div class="option-container">
    <p class="question-stem">{{ stem }}</p>
    <div style="flex: 1;overflow-y: auto;padding-top: 20px;padding-bottom: 0px;box-sizing: border-box">
      <div v-for="(option,index) in options"
           :key="index"
           style="display: flex;align-items: center;margin-bottom: 20px;cursor: pointer"
           @click="clickOption(option)">
        <p class="option-index" :style="customOptionStyle(option)">{{ getAnswerByIndex(index) }}</p>
        <p style="width: 20px"></p>
        <div class="option-item" :style="customOptionStyle(option)">
          <p style="flex: 1">{{ option.value }}</p>
          <div v-if="option.reviewOption">
            <img src="../assets/answer_correct.png" style="width: 32px;height: 32px"
                 v-if="option.styleType == 'success'">
            <img src="../assets/answer_error.png" style="width: 32px;height: 32px"
                 v-else-if="option.styleType == 'failure'">
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 10px;text-align: center">
      <p class="submit-answer" @click="gotoSubmitAnswer">Submit</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "QuestionOptionView",
  props: {
    question: {
      type: Object,
      default: {}
    }
  },
  watch: {
    question: {
      handler(newVal, oldVal) {
        this.formatOptions(newVal)
      },
      immediate: true,
    },
  },
  data() {
    return {
      stem: '',
      reviewOption: false,
      options: [],
      correct: []
    }
  },
  methods: {

    gotoSubmitAnswer() {
      this.$emit("submitAnswer");
    },

    customOptionStyle(option) {
      let style = {}
      if (option.reviewOption) {
        let styleType = option.styleType
        if (styleType == 'normal') {
          style.background = '#FFFFFF'
          style.color = '#333333'
        } else if (styleType == 'failure') {
          style.background = '#FFD0DB'
          style.color = '#D60034'
        } else if (styleType == 'success') {
          style.background = '#E0FFF1'
          style.color = '#00C167'
        }
      } else {
        if (option.selected) {
          style.background = '#E0FFF1'
          style.color = '#00C167'
        } else {
          style.background = '#FFFFFF'
          style.color = '#333333'
        }
      }
      return style
    },
    formatOptions(newVal) {
      this.resetData()
      this.stem = newVal.question.stem
      this.correct = newVal.question.correct
      let temp_options = newVal.question.options

      if (newVal.record != null) {
        this.reviewOption = true
      }
      var answers = []
      if (this.reviewOption) {
        answers = newVal.record.answers
      }
      for (let i = 0; i < temp_options.length; i++) {
        let item = temp_options[i]
        let obj = {
          selected: false,
          reviewOption: this.reviewOption,
          value: item
        }
        if (this.reviewOption) {
          let styleType = this.getOptionStyleType(i, answers, this.correct)
          obj['styleType'] = styleType
        }
        this.options.push(obj)
      }
    },

    //normal success ,failure
    getOptionStyleType(index, answers, correct) {
      //还要显示正确答案
      var styleType = 'normal'
      let temp_answer = this.getAnswerByIndex(index)
      for (let i = 0; i < answers.length; i++) {//当前的OPTION 是否被选择了
        if (temp_answer == answers[i]) {
          styleType = 'failure'
          break
        }
      }
      if (styleType == 'normal') {
        for (let i = 0; i < correct.length; i++) {//查看选择的是否正确
          if (temp_answer == correct[i]) {
            styleType = 'success'
            break
          }
        }
        return styleType
      }
      for (let i = 0; i < correct.length; i++) {//查看选择的是否正确
        if (temp_answer == correct[i]) {
          styleType = 'success'
          break
        }
      }
      return styleType
    },

    resetData() {
      this.reviewOption = false
      this.stem = ''
      this.options = []
      this.correct = []
    },

    clickOption(option) {
      if (option.reviewOption) {
        return
      }
      option.selected = !option.selected
    },
    getSelectAnswers() {

      if (this.reviewOption) {
        this.$message.error('You have already answered this question.')
        return
      }
      let answers = [];
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].selected) {
          answers.push(this.getAnswerByIndex(i))
        }
      }
      if (answers.length <= 0) {
        this.$message.error('Please make your choice.')
        return
      }
      var right = false
      let correctCount = this.correct.length
      let answerCount = answers.length
      let rightCount = 0
      let errorCount = 0

      //选择的个数>正确个数，直接错误～
      if (answerCount > correctCount) {
        //里面有错误
        this.$alert(this.question.question.error_hint, 'Notice', {
          confirmButtonText: 'OK',
        })
        return
      }

      for (let i = 0; i < answerCount; i++) {
        let haveRight = false
        for (let j = 0; j < correctCount; j++) {
          if (answers[i] == this.correct[j]) {
            haveRight = true
            break
          }
        }
        if (haveRight) {
          rightCount++
        } else {
          errorCount++
        }
      }

      //，答案B，我选AB会提交成功 ,right = 1 error = 1
      if (rightCount <= 0) {
        //一个正确都没有
        this.$alert(this.question.question.error_hint, 'Notice', {
          confirmButtonText: 'OK',
        })
        return
      }

      if (rightCount < correctCount) {
        if (errorCount > 0) {
          //里面有错误
          this.$alert(this.question.question.error_hint, 'Notice', {
            confirmButtonText: 'OK',
          })
        } else {
          this.$message.warning(this.question.question.miss_select_hint)
        }
        return
      }
      if (rightCount > correctCount) {
        this.$alert(this.question.question.error_hint, 'Notice', {
          confirmButtonText: 'OK',
        })
        return
      }

      //兜底，就怕有漏掉的
      if (rightCount == correctCount && errorCount == 0) {
        right = true
      } else {
        this.$alert(this.question.question.error_hint, 'Notice', {
          confirmButtonText: 'OK',
        })
      }
      if (right == false) {
        return
      }
      return {answers: answers, right: right}
    },
    getAnswerByIndex(i) {
      switch (i) {
        case 0:
          return 'A'
        case 1:
          return 'B'
        case 2:
          return 'C'
        case 3:
          return 'D'
        case 4:
          return 'E'
        case 5:
          return 'F'
      }
    }
  }
}
</script>

<style>
::-webkit-scrollbar {
  display: none;
}
</style>

<style scoped lang="less">

.option-container {
  z-index: 1002;
  margin: 0 auto 1.85em;
  width: 85%;
  padding: 20px 40px;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 15px 15px 15px 15px;
  display: flex;
  flex-direction: column;
}

.question-stem {
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0-85 Bold, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #FFFFFF;
}

.option-index {
  text-align: center;
  width: 48px;
  height: 44px;
  line-height: 44px;
  background: #FFFFFF;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #333333;
}

.option-item {
  padding: 16px 32px;
  box-sizing: border-box;
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #333333;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.submit-answer {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 140px;
  line-height: 44px;
  height: 44px;
  background: #D60034;
  border-radius: 24px 24px 24px 24px;
  font-size: 18px;
  font-family: Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #FFFFFF;
}

.option-item:last-child {
  margin-bottom: 0px;
}
</style>
