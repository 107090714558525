<template>
  <div class="option-container">
    <div class="lastNameStyle">
      {{ lastName }}
    </div>
    <div style="width: 20px">
    </div>
    <div id="questionOptionContainer" class="questionOptionContainer">
      <div v-html="showOptionContent"></div>
    </div>
    <img src="../assets/close.png" @click="clickCloseView"
         style="position: absolute;top: 8px;right: 5px;width: 20px;height: 20px;cursor: pointer">
  </div>
</template>
<script>
import {getLastName} from "../utils/store";

export default {
  name: "QuestionReviewOptionView",
  props: {
    question: {
      type: Object,
      default: {}
    }
  },
  watch: {
    question: {
      handler(newVal, oldVal) {
        this.formatOptions(newVal)
      },
      immediate: true,
    },
  },
  data() {
    return {
      lastName: getLastName(),
      showOptionContent: '',
      optionContent: '',
      internalTimer: undefined
    }
  },
  beforeDestroy() {
    if (this.internalTimer) {
      clearInterval(this.internalTimer)
    }
  },
  methods: {

    clickCloseView() {
      this.$emit('closeView')
    },

    formatOptions(newVal) {
      this.resetData()
      let temp_options = newVal.options
      let reviewOption = false
      if (newVal.record != null) {
        reviewOption = true
      }
      if (reviewOption == false) {
        return
      }
      let correct = newVal.correct
      var answers = newVal.record.answers
      let options = []
      for (let i = 0; i < temp_options.length; i++) {
        let styleType = this.getOptionStyleType(i, answers, correct)
        if (styleType == 'success') {
          options.push(temp_options[i])
          // options.push(temp_options[i])
        }
      }
      for (let i = 0; i < options.length; i++) {
        this.optionContent = this.optionContent + options[i]
        if (i != temp_options.length - 1) {
          this.optionContent = this.optionContent + '<br>'
        }
      }
      let totalTime = newVal.ddlTime - 1
      console.log('newVal.ddlTime:' + totalTime)
      let offSetSeconds = 0;
      // if (newVal.offSetSeconds) {
      //   offSetSeconds = newVal.offSetSeconds
      // }
      //去掉一部分内容
      let optionLength = this.optionContent.length
      console.log('optionLength :' + optionLength)
      // console.log('optionLength :' + optionLength)
      // let offsetLength = offSetSeconds > 0 ? parseInt((offSetSeconds * optionLength) / totalTime) : 0
      // console.log('offsetLength :' + offsetLength)
      let internalTime = parseInt((totalTime * 1000) / optionLength)//parseInt(((totalTime - offSetSeconds) * 1000) / (optionLength - offsetLength))
      // this.optionContent = this.optionContent.slice(offsetLength, optionLength)
      console.log('internalTime :' + internalTime)
      console.log('optionContent :' + this.optionContent)
      this.startOptionInternal(internalTime)
    },
    //设置开始打印
    startOptionInternal(internalTime) {
      let optionContent = this.optionContent
      let contentLength = optionContent.length
      let that = this
      let contentIndex = 0
      this.internalTimer = setInterval(() => {
        let newValue = optionContent.slice(0, contentIndex)
        that.showOptionContent = newValue
        that.scrollDown()
        contentIndex++;
        if (contentIndex >= contentLength) {
          clearInterval(this.internalTimer)
        }
      }, internalTime)
    },
    resetData() {
      if (this.internalTimer) {
        clearInterval(this.internalTimer)
      }
      this.showOptionContent = ''
      this.optionContent = ''
    },
    scrollDown() {
      this.$nextTick(() => {
        let div = document.getElementById("questionOptionContainer");
        div.scrollTop = div.scrollHeight;
      });
    },
    //normal success ,failure
    getOptionStyleType(index, answers, correct) {
      //还要显示正确答案
      var styleType = 'normal'
      let temp_answer = this.getAnswerByIndex(index)
      for (let i = 0; i < answers.length; i++) {//当前的OPTION 是否被选择了
        if (temp_answer == answers[i]) {
          styleType = 'failure'
          break
        }
      }
      //被选择了，但是不是正确的
      //没有被选择，查看是否是正确答案
      if (styleType == 'normal') {
        for (let i = 0; i < correct.length; i++) {//查看选择的是否正确
          if (temp_answer == correct[i]) {
            styleType = 'success'
            break
          }
        }
        return styleType
      }
      for (let i = 0; i < correct.length; i++) {//查看选择的是否正确
        if (temp_answer == correct[i]) {
          styleType = 'success'
          break
        }
      }
      return styleType
    },
    getAnswerByIndex(i) {
      switch (i) {
        case 0:
          return 'A'
        case 1:
          return 'B'
        case 2:
          return 'C'
        case 3:
          return 'D'
        case 4:
          return 'E'
        case 5:
          return 'F'
      }
    }
  }
}
</script>

<style>
::-webkit-scrollbar {
  display: none;
}
</style>

<style scoped lang="less">

.questionOptionContainer {
  overflow-y: auto;
  background-color: #E0FFF1;
  padding: 6px 18px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  flex: 1;
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: #00C167;
  position: relative;
}

.lastNameStyle {
  border-radius: 50%;
  box-sizing: border-box;
  width: 84px;
  height: 84px;
  background-color: #D60034;
  color: white;
  text-align: center;
  line-height: 84px;
  font-size: 34px
}

.option-container {
  position: relative;
  z-index: 1002;
  margin: 0px auto;
  width: 100%;
  height: 100%;
  display: flex;
}

</style>
