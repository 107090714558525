<template>
  <video ref="videoPlayer" style="object-fit: cover" class="video-js vjs-big-play-centered" preload muted autoplay controls/>
</template>

<script>
import videojs from 'video.js';
import {Loading} from 'element-ui';

export default {
  name: "VideoView",
  data() {
    return {
      videoDuration: 0,
      videoSource: '',
      player: undefined,
      videoTimeUpdated: false
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
      this.player = null
    }
  },
  methods: {

    pausePlayer() {
      if (this.player) {
        this.player.pause()
      }
    },

    getVideoCurrentTime() {
      return this.player.currentTime()
    },

    addVideoSourceAndToPlay(source, ddlTime,enableAutoPlay) {
      this.videoTimeUpdated = false
      // console.log('lru :' + source)
      if (this.player && enableAutoPlay == false) {
        console.log('重新加载');
        this.player.src(source);  //重置video的src
        this.player.load(source);  //使video重新加载
        return
      }
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)'
      });
      console.log('create new ');
      let videoOptions = {
        autoplay: enableAutoPlay,
        controls: true,
        // fluid: true,
        muted: false,
        sources: [
          {
            src: source,
            // type: "video/mp4"
          }
        ]
      }
      let that = this
      this.player = videojs(this.$refs.videoPlayer, videoOptions)
      // this.player.aspectRatio('16:9')

      this.player.on('ready', function () {
        var _this = this
        that.$emit('videoReady')
        console.log('---read-----')
        console.log(_this)
        setTimeout(function () {
          _this.volume(0.75);
        }, 20);
        loadingInstance.close()
        _this.on('timeupdate', function () {
          if (that.videoTimeUpdated == false) {
            let progress = parseInt(this.currentTime())
            if (progress > 0) {
              that.videoTimeUpdated = true
              that.$emit('videoTimeUpdated')
            }
          }
        })
      })
      this.player.on('play', function () {
        console.log('play-----')
        that.$emit('videoPlay')
      })
      this.player.on('ended', function () {
        that.videoTimeUpdated = false
        that.$emit('videoEnded')
      })
      var controlBar = this.player.controlBar
      var rotateBtn = controlBar.addChild('button')
      rotateBtn.addClass('vjs-icon-replay')
      rotateBtn.on('click', function () {
        that.videoTimeUpdated = false
        that.player.currentTime(0)
        that.player.play()
        that.$emit('videoPlay')
        that.$emit('hideReviewQuestionView')
      })
      this.player.on('error', function (e) {
        console.log('ended---');
        that.videoTimeUpdated = false
        loadingInstance.close()
        that.$message.error('Playback failed, please refresh and try again.')
      })
    },
  }
}
</script>

<style scoped>

</style>
